@charset "utf-8";

//-----------------------------------------------
// responsive setting
//-----------------------------------------------
$pc: "print, screen and (min-width: 768px) and (max-width: 9999px)";
$sp: "only screen and (min-width: 0px) and (max-width: 767px)";

/*===============================================
 responsive element
===============================================*/
@media #{$pc} {
	.sp-inline,
	.sp-inline-block,
	.sp-block {
		display: none !important;
	}
	.pc-inline {
		display: inline !important;
	}
	.pc-inline-block {
		display: inline-block !important;
	}
	.pc-block {
		display: block !important;
	}
}

@media #{$sp} {
	.pc-inline,
	.pc-inline-block,
	.pc-block {
		display: none !important;
	}
	.sp-inline {
		display: inline !important;
	}
	.sp-inline-block {
		display: inline-block !important;
	}
	.sp-block {
		display: block !important;
	}
}
