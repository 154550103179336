@charset "utf-8";

/*===============================================
 password
===============================================*/
.password {
	margin: 32px 0 0;
}
.password-input {
	margin: 24px 0 0 0;
	
	input[type="password"] {
		padding: 0.25em;
		margin: 0 1em 0 0;
	}
}
