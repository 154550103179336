@charset "utf-8";

/*===============================================
 common
===============================================*/
img {
	max-width: 100%;
	height: auto;
}

a:hover {
	opacity: 0.7;
}

/*===============================================
 layout
===============================================*/
html {
	height: 100%;
	overflow-y: scroll;
}

/*===============================================
 header
===============================================*/
.header {
	// padding: percentage( 60 / $pc-base ) 0 percentage( 48 / $pc-base );
	padding: 30px 0 24px;
	
	@media #{$sp} {
		padding: percentage( 30 / $sp-base ) 0 percentage( 24 / $sp-base );
	}
	
	.logo {
		width: 111.5px;
		margin: 0 auto;
		
		@media #{$sp} {
			width: percentage( 223 * 0.75 / $sp-base );
		}
	}
}

/*===============================================
 contents
===============================================*/
.contents {
	padding: 0 percentage( ($pc-base - 2200) / 2 / $pc-base );
	background: url(#{$img}background_pc_w2560.png) repeat-y center top;
	background-size: 100% auto;
	
	@media #{$sp} {
		padding: 0 6%;
		background-image: url(#{$img}background_sp_w750.png);
	}
	
	.main {
		max-width: #{$pc-content-base}px;
		margin: 0 auto percentage( 200 / 2200 );
		
		@media #{$sp} {
			margin: 0 0 percentage( 100 / 680 );
		}
	}
	.article-header {
		max-width: #{$pc-content-base}px;
		margin: 0 auto 36px;
		box-sizing: border-box;
		
		@media #{$sp} {
			margin: 0 0 percentage( 36 / 680 );
		}
		
		.article-tags {
			display: inline-block;
			font-size: 16px;
			line-height: 1;
			
			@media #{$sp} {
				font-size: #{(24 / $sp-base * 100)}vw;
			}
			
			li {
				display: inline-block;
				
				span {
					display: inline-block;
					padding: 0.5em 1em;
					
					&.category-1 {
						background-color: $color1;
						color: #fff;
						letter-spacing: 0.075em;
					}
					&.category-2 {
						background-color: $color2;
						color: #fff;
						letter-spacing: 0.075em;
					}
				}
			}
		}
		.article-date {
			display: inline-block;
			margin: 0 0 0 1.2em;
			font-size: 16px;
			line-height: #{(32 / 18)};
			
			@media #{$sp} {
				font-size: #{(22 / $sp-base * 100)}vw;
			}
		}
	}
	.article-contents {
		max-width: #{$pc-content-base}px;
		margin: 0 auto;
		padding: 0 20px;
		
		@media #{$sp} {
			padding: 0;
		}
		
		.article-ttl {
			color: $color1;
			// font-size: 38px;
			font-size: 28px;
			font-weight: normal;
			line-height: 1.7;
			
			@media #{$sp} {
				font-size: #{(36 / $sp-base * 100)}vw;
				line-height: 1.4;
			}
		}
		.article-editor {
			margin: 32px 0 0 0;
			
			@media #{$sp} {
				margin: percentage( 48 / 660 ) 0 0 0;
			}
			
			p {
				margin: 1.5em 0 0 0;
				font-size: 16px;
				line-height: 1.75;
				
				@media #{$sp} {
					font-size: #{(26 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin: 0;
				}
			}
		}
		.article-second-ttl {
			color: $black;
			font-size: 20px;
			font-weight: bold;
			line-height: 1.7;
			
			@media #{$sp} {
				font-size: #{(32 / $sp-base * 100)}vw;
				line-height: 1.4;
			}
		}
		.article-second-lead {
			margin: 24px 0 0 0;
			
			@media #{$sp} {
				margin: percentage( 48 / 660 ) 0 0 0;
			}
			
			p {
				margin: 1.5em 0 0 0;
				font-size: 16px;
				font-weight: 500;
				line-height: 1.5;
				
				@media #{$sp} {
					font-size: #{(26 / $sp-base * 100)}vw;
				}
				
				&:first-child {
					margin: 0;
				}
			}
		}
	}
}

/*===============================================
 footer
===============================================*/
.footer {
	padding: 90px 0 60px;
	
	@media #{$sp} {
		padding: percentage( 90 / 680 ) 0 percentage( 60 / 680 );
	}
	
	.copyright {
		text-align: center;
		color: $color1;
		font-size: 15px;
		line-height: 1.2;
		
		@media #{$sp} {
			font-size: #{(22 / $sp-base * 100)}vw;
		}
	}
}