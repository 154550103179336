@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 400;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Bold");
	font-weight: bold;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Yu Gothic", YuGothic, sans-serif;
	font-feature-settings : "palt";
}
