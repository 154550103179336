@charset "utf-8";

/*===============================================
 event-entry
===============================================*/
.event-entry {
	padding: 48px 0;
	
	@media #{$sp} {
		padding: percentage( 48 / $sp-base ) 0;
	}
	
	.event-entry-form {
		margin: 72px 0 0 0;
		
		@media #{$sp} {
			margin: percentage( 72 / $sp-base ) 0 0 0;
		}
		
		dl {
			max-width: 770px;
			margin: 64px 0 0 0;
			
			@media #{$sp} {
				margin: percentage( 64 / $sp-base ) 0 0 0;
			}
			
			&:first-child {
				margin: 0;
			}
			dt {
				padding: 0 0 0 1.2em;
				text-indent: -1.2em;
				font-size: 18px;
				font-weight: bold;
				
				@media #{$sp} {
					font-size: #{(32 / $sp-base * 100)}vw;
				}
				
				&:before {
					display: inline-block;
					width: 0.7em;
					height: 0.7em;
					margin: 0 0.4em 0 0;
					background-color: $black;
					vertical-align: 0;
					content: "";
				}
				.required {
					display: inline-block;
					padding: 2px 6px 3px;
					border: 1px solid $color3;
					margin: 0 0 0 18px;
					color: $color3;
					font-size: 11px;
					font-weight: bold;
					line-height: 1;
					vertical-align: top;
					text-indent: 0;
					
					@media #{$sp} {
						font-size: #{(26 / $sp-base * 100)}vw;
					}
				}
			}
			dd {
				margin: 24px 0 0 0;
				
				@media #{$sp} {
					margin: percentage( 32 / $sp-base ) 0 0 0;
				}
				
				p {
					margin: 32px 0 0 0;
					font-size: 16px;
					line-height: 1.5;
					@include clearfix();
					
					@media #{$sp} {
						margin: percentage( 32 / $sp-base ) 0 0 0;
						font-size: #{(26 / $sp-base * 100)}vw;
					}
					
					&:first-child {
						margin: 0;
					}
					.wpcf7-form-control-wrap {
						float: left;
						display: block;
						font-size: 20px;
						line-height: 1;
						
						@media #{$sp} {
							font-size: #{(32 / $sp-base * 100)}vw;
						}
					}
					.wpcf7-not-valid-tip {
						margin: 1em 0 0 0;
						color: $color3;
						font-size: 13px;
						
						@media #{$sp} {
							font-size: #{(26 / $sp-base * 100)}vw;
						}
					}
				}
				.notes {
					display: block;
					margin: 1.2em 0 0 0;
					color: $color3;
					font-size: 13px;
					font-weight: 500;
					line-height: 1;
					
					@media #{$sp} {
						font-size: #{(26 / $sp-base * 100)}vw;
					}
				}
			}
		}
		input,
		textarea {
			font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Yu Gothic", YuGothic, sans-serif;
		}
		input[type="text"],
		input[type="tel"],
		input[type="email"],
		textarea {
			width: 100%;
			padding: 1em;
			box-sizing: border-box;
			border: 2px solid $black;
			font-size: 20px;
			line-height: 1.2;
			
			@media #{$sp} {
				font-size: #{(32 / $sp-base * 100)}vw;
			}
			
			&::placeholder {
				color: $gray;
			}
			// IE
			&:-ms-input-placeholder {
				color: $gray;
			}
			// Edge
			&::-ms-input-placeholder {
				color: $gray;
			}
			&.wpcf7-not-valid {
				background-color: $color3-a;
			}
		}
		textarea {
			height: 250px;
			resize: none;
			
			@media #{$sp} {
				height: 8em;
			}
		}
		.your-name-1,
		.your-name-2,
		.your-kana-1,
		.your-kana-2,
		.your-tel-1,
		.your-tel-2,
		.your-zip,
		.your-pref,
		.your-job {
			width: 48.5%;
			margin: 0 0 0 3%;
			
			&:first-child {
				margin: 0;
			}
		}
		.your-tel-1 {
			@media #{$sp} {
				width: 100%;
			}
		}
		.your-tel-2 {
			@media #{$sp} {
				width: 100%;
				margin: 3% 0 0 0;
			}
		}
		.your-email,
		.your-email_confirm,
		.your-address-1,
		.your-address-2,
		.your-message,
		.your-companion {
			width: 100%;
		}
		.your-pref,
		.your-birth-year,
		.your-birth-month,
		.your-birth-date,
		.your-job,
		.your-quantity,
		.your-quantity_confirm {
			position: relative;
			display: block;
			
			select {
				display: block;
				appearance: none;
				border: 0;
				box-sizing: border-box;
				width: 100%;
				padding: 1em 3.6em 1em 1em;
				border: 2px solid $black;
				background-color: $white;
				font-size: 20px;
				line-height: 1.2;
				cursor: pointer;
				
				@media #{$sp} {
					font-size: #{(32 / $sp-base * 100)}vw;
				}
				&.wpcf7-not-valid {
					background-color: $color3-a;
				}
			}
			&:before {
				position: absolute;
				right: 0;
				top: 0;
				width: 3.2em;
				height: 3.2em;
				border: 2px solid $black;
				background-color: #8B8B8B;
				pointer-events: none;
				content: "";
			}
			&:after {
				position: absolute;
				right: 1.4em;
				top: 1.3em;
				width: 0.5em;
				height: 0.5em;
				border-right: 2px solid $white;
				border-bottom: 2px solid $white;
				pointer-events: none;
				transform: rotate(45deg);
				content: "";
			}
		}
		.your-pref {
			@media #{$sp} {
				width: 100%;
			}
		}
		.your-birth-year {
			width: 27.5%;
			
			@media #{$sp} {
				width: 48.5%;
				margin: 0 0 3% 0;
			}
		}
		.your-birth-month,
		.your-birth-date {
			width: 22.5%;
			margin: 0 0 0 3%;
			
			@media #{$sp} {
				width: 48.5%;
			}
		}
		.your-birth-month {
			@media #{$sp} {
				margin: 0;
				clear: left;
			}
		}
		.your-job {
			@media #{$sp} {
				width: 100%;
			}
		}
		.your-quantity,
		.your-quantity_confirm {
			width: 48.5%;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		.your-quantity_confirm {
			select {
				pointer-events: none;
			}
			&:before {
				display: none;
			}
			&:after {
				display: none;
			}
		}
		.your-event-key {
			width: 48.5%;
			pointer-events: none;
			
			@media #{$sp} {
				width: 100%;
			}
		}
	}
	.entry-form-submit {
		margin: 80px 0 0 0;
		text-align: center;
		
		@media #{$sp} {
			margin: percentage( 80 / $sp-base ) 0 0 0;
		}
		
		input[type="submit"] {
			display: inline-block;
			padding: 1em 2.5em;
			border: 0;
			background-color: $color1-a;
			color: $white;
			font-size: 20px;
			font-weight: bold;
			line-height: 1;
			letter-spacing: 0.05em;
			cursor: pointer;
			
			@media #{$sp} {
				font-size: #{(32 / $sp-base * 100)}vw;
			}
			
			&:hover {
				opacity: 0.9;
			}
		}
	}
}