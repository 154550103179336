@charset "utf-8";

/*===============================================
 sale-single
===============================================*/
.sale-detail {
	margin: 96px 0 0;
	
	@media #{$sp} {
		margin: percentage( 96 / 680 ) 0 0;
	}
	
	.sale-detail-ttl {
		color: $color1;
		font-size: 26px;
		font-weight: normal;
		line-height: 1;
		
		@media #{$sp} {
			font-size: #{(34 / $sp-base * 100)}vw;
		}
	}
	.sale-item-container {
	}
	.sale-item {
		padding: 64px 0;
		border-top: 1px solid #898989;
		@include clearfix();
		
		&:first-child {
			border-top: 0;
		}
		
		@media #{$sp} {
			padding: percentage( 64 / 680 ) 0;
		}
		
		.sale-item-image {
			float: left;
			width: percentage( 144 / 404 );
			
			@media #{$sp} {
				float: none;
				width: 100%;
			}
			
			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
		.sale-item-info {
			float: right;
			width: percentage( 236 / 404 );
			
			@media #{$sp} {
				float: none;
				width: 100%;
				margin: percentage( 48 / 680 ) 0 0 0;
			}
			
			.sale-item-icons {
				margin: 0 0 1em 0;
				font-size: 16px;
				line-height: 1.75;
				@include clearfix();
				
				@media #{$sp} {
					font-size: #{(24 / $sp-base * 100)}vw;
				}
				
				li {
					float: left;
					padding: 0.5em;
					border: 1px solid $color1;
					color: $color1;
					font-weight: bold;
					line-height: 1;
				}
			}
			.sale-item-series {
				color: $color1;
				font-size: 22px;
				font-weight: normal;
				
				@media #{$sp} {
					font-size: #{(24 / $sp-base * 100)}vw;
				}
			}
			.sale-item-ttl {
				margin: 0.75em 0 0 0;
				color: $color1;
				font-size: 26px;
				font-weight: normal;
				
				@media #{$sp} {
					font-size: #{(26 / $sp-base * 100)}vw;
				}
			}
			.sale-item-price {
				margin: 0.4em 0 0 0;
				color: $color1;
				font-size: 36px;
				font-weight: normal;
				
				@media #{$sp} {
					font-size: #{(32 / $sp-base * 100)}vw;
				}
				
				small {
					font-size: 20px;
					
					@media #{$sp} {
						font-size: #{(20 / $sp-base * 100)}vw;
					}
				}
			}
			.sale-item-description {
				margin: 1.5em 0 0 0;
				font-size: 16px;
				line-height: 1.75;
				
				@media #{$sp} {
					font-size: #{(26 / $sp-base * 100)}vw;
				}
			}
			.sale-item-cart {
				margin: 2em 0 0 0;
				font-size: 16px;
				line-height: 1.75;
				
				@media #{$sp} {
					text-align: center;
					font-size: #{(26 / $sp-base * 100)}vw;
				}
				
				a {
					display: inline-block;
					padding: 1em 1em 1em 1.5em;
					background-color: $color1;
					text-decoration: none;
					color: white;
					
					&:before {
						display: inline-block;
						width: 1.45em;
						height: 1.38em;
						margin: 0 0.75em 0 0;
						background: url(#{$img}ico-cart.png) no-repeat;
						background-size: contain;
						vertical-align: -0.2em;
						content: "";
					}
				}
			}
		}
	}
}