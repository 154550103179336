@charset "utf-8";

//-----------------------------------------------
// clearfix
//-----------------------------------------------
@mixin clearfix() {
	*zoom: 1;
	
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
