@charset "utf-8";

/*===============================================
 event-single
===============================================*/
.event-detail {
	margin: 96px 0 0;
	
	@media #{$sp} {
		margin: percentage( 96 / 680 ) 0 0;
	}
	
	.event-detail-ttl {
		color: $color1;
		font-size: 26px;
		font-weight: normal;
		line-height: 1;
		
		@media #{$sp} {
			font-size: #{(34 / $sp-base * 100)}vw;
		}
	}
	.event-detail-editor {
		margin: 48px 0 0 0;
		
		@media #{$sp} {
			margin: percentage( 48 / 660 ) 0 0 0;
		}
		
		p {
			margin: 1.5em 0 0 0;
			font-size: 16px;
			line-height: 1.75;
			
			@media #{$sp} {
				font-size: #{(26 / $sp-base * 100)}vw;
			}
			
			&:first-child {
				margin: 0;
			}
			
			strong {
				font-size: 140%;
				font-weight: normal;
				
				@media #{$sp} {
					font-size: 125%;
				}
			}
		}
	}
	.event-detail-link {
		margin: 86px 0 0 0;
		text-align: center;
		
		@media #{$sp} {
			margin: percentage( 86 / 660 ) 0 0 0;
		}
		
		a {
			display: inline-block;
			padding: 1.3em;
			background-color: $color1;
			text-decoration: none;
			color: #fff;
			font-size: 18px;
			font-weight: normal;
			
			@media #{$sp} {
				font-size: #{(28 / $sp-base * 100)}vw;
			}
		}
	}
}
